<template>
  <TitleMeeting title="Confirmez votre rendez-vous" />
  <SelectedMeeting :date="date" />
  <ReminderMeeting v-if="!this.isBoostPack" :date="date" :emailChange="emailChange" />
  <form class="confirm-coaching" @submit.prevent="validateMeeting">
  <button :disabled="waitingValidation" class="center-button btn-pink">Valider mon rendez-vous</button>
  </form>
</template>

<script>
import TitleMeeting from "@/components/Meeting/TitleMeeting";
import SelectedMeeting from "@/components/Meeting/SelectedMeeting";
import ReminderMeeting from "@/components/Meeting/ReminderMeeting";
import moment from "moment/moment";
import meetingsService from '@/services/meetings.service';

export default {
  name: "MeetingValidationView",
  components: {ReminderMeeting, SelectedMeeting, TitleMeeting},
  data() {
    return {
      date: null,
      waitingValidation: false,
      email: null,
      isBoostPack: false
    }
  },
  created() {
    this.isBoostPack = !!this.$store.getters['user/getBoostPack']
  },
  methods: {
    emailChange(email) {
      this.email = email
    },
    validateMeeting() {
      if(!this.waitingValidation) {
        this.waitingValidation = true;
          meetingsService.createMeeting(moment(this.date).format(), this.email ? this.email : null)
          .then((response) => {
            this.$store.commit("meetingStore/resetState")
              if (this.isBoostPack) {
                this.$router.push({name: 'MeetingConfirmation', params: { id: response.data.data.meeting.id }})
              } else {
                  //this.$router.push({name: 'MeetingCriterias', params: { id: response.data.data.meeting.id }})
              }
          });
      }
    }
  },
  mounted() {
    this.date = this.$store.state.meetingStore.date;
    if (!this.date) {
      this.$router.push('Meeting')
    }

    document.body.classList.add('bg-grey')
  },
  unmounted () {
    document.body.classList.remove('bg-grey')
  },
}
</script>
