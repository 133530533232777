<template>
  <div class="reminder-meeting">
    <h2>Avoir un rappel</h2>
    <p>Ajoutez votre adresse e-mail pour obtenir un rappel</p>
    <p>Promis, ce mail ne nous servira à rien d'autre que de mémo pour vous!</p>
    <form @submit.prevent="validateMeeting">
      <div class="form-group center">
        <input placeholder="E-mail" type="email" v-model="email" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ReminderMeeting",
  props: {
    date: String,
    emailChange: Function
  },
  data() {
    return {
      email: ''
    }
  },
  watch: {
    'email': function () {
      this.emailChange(this.email)
    }
  }
}
</script>
