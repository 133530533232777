<template>
  <div class="meeting-bullet">
    <div class="meeting">
      <div class="column">
        <div class="yellow">
          <p>
            <strong>Date et heure</strong>{{ formatedDate }}
          </p>
          <p><strong>Durée</strong>30 min</p>
        </div>
        <router-link :to="{ name: 'Meeting'}">Modifier mon rendez-vous</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SelectedMeeting",
  props: {
    date: String
  },
  computed: {
    formatedDate() {
      return moment(this.date).format('dddd Do MMMM à HH:mm').toLowerCase()
    }
  }
}
</script>